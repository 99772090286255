
function Portfolio() {

   
    return (
        <p id="top">
            <div align="center"> This portfolio website could be consisdered as a project of mine. Built with React, TailwindCSS, Framer Motion, and deployed on Netlify </div>

            <p align="right">(<a href="#top">back to top</a>)</p>




        </p>
    )
}
export default Portfolio;